"use client";

import { useSearchParams } from "next/navigation";
import { Translation } from "../Translation/Translation.component";
type PaginationPageDisplayProps = {
  readonly totalPages?: null | number;
};
export function PaginationPageDisplay({
  totalPages = null
}: PaginationPageDisplayProps) {
  const searchParams = useSearchParams();
  const currentPage = Number(searchParams.get("page") ?? "1");
  return <p className="w-full py-1 text-center text-sm text-nobel" data-sentry-component="PaginationPageDisplay" data-sentry-source-file="PaginationPageDisplay.component.tsx">
      <Translation da={`Side ${currentPage}`} de={`Seite ${currentPage}`} en={`Page ${currentPage}`} no={`Side ${currentPage}`} sv={`Sida ${currentPage}`} data-sentry-element="Translation" data-sentry-source-file="PaginationPageDisplay.component.tsx" />

      {totalPages !== null ? <Translation da={` af ${totalPages}`} de={` von ${totalPages}`} en={` of ${totalPages}`} no={` av ${totalPages}`} sv={` av ${totalPages}`} /> : null}
    </p>;
}